<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    :header="null"
    width="50rem"
    class="duplicate-folder-modal"
  >
    <div class="duplicate-folder-modal-container">
      <div class="duplicate-folder-modal-container__header">
        <h1>Duplicate Folder</h1>
      </div>
      <div class="duplicate-folder-modal-container__body">
        <a-select
          v-model:value="folderType"
          placeholder="Please select a folder type"
          :options="options"
        >
        </a-select>
        <p v-if="errorMessage" class="duplicate-folder-modal-container__body--error-msg">{{errorMessage}}</p>
        <a-button
          :loading="duplicateModalLoader"
          @click="()=>{this.$emit('duplicateFolder', this.folderType)}"
          class="duplicate-folder-modal-container__body--btn"
        >
          Add
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    duplicateModalLoader: {
      type: Boolean,
      default: () => (false)
    },
    errorMessage:{
      type:String,
      default:()=>('')
    }
  },
  data() {
    return {
      folderType: null,
      loader: false,
      options: [
        {
          label: "Private",
          value: "private_folders",
        },
        {
          label: "Public",
          value: "public_folders",
        },
        {
          label: "Organization",
          value: "organization_folders",
        },
      ],
    };
  },
  emits: ["close-modal", "duplicateFolder"],
  computed: {},
  watch:{
    visible:{
      handler(val){
        if (!val)
          this.folderType = null
      },
      immediate: true
    } 
  },
  methods: {
    closeModal() {
      this.loader = false;
      this.folderType = null;
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.duplicate-folder-modal {
  top: 15rem !important;
  .ant-modal-content {
    background-color: #f6f8ff;

    .ant-modal-close {
      margin: 1.9rem 2rem;
    }
    .ant-modal-body {
      padding: 0;
      .duplicate-folder-modal-container {
        padding: 2.2rem 2.5rem;
        &__header {
          margin-bottom: 2.8rem;
          h1 {
            font-size: 2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: normal;
            color: $color-black;
          }
        }
        &__body {
          .ant-select {
            width: 100% !important;
            height: 4rem !important;
            font-family: $font-primary;
            font-size: 1.6rem;
            color: $color-black;
            .ant-select-selector {
              padding: 0 1.5rem;
              border: 1px solid #00000033;
              border-radius: 0.5rem !important;
              box-shadow: none;
              .ant-select-selection-search {
                display: none !important;
              }
              .ant-select-selection-placeholder {
                color: $color-black;
                opacity: 0.4;
                font-family: $font-primary;
                font-size: 1.6rem;
                height: 100%;
                display: flex;
                align-items: center;
                padding-right: 1.8rem;
              }
            }
            .ant-select-arrow {
              display: flex;
              align-items: center;
              justify-content: center;
              right: 1.5rem;  
              .anticon {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                svg {
                  width: 1.2rem;
                  height: 1.2rem;
                  fill: $color-black;
                }
              }
            }
          }
          &--error-msg {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin: 0.8rem 0 0;
          }
          &--btn {
            margin-top: 3rem;
            outline: none;
            border: none;
            background-color: $color-primary;
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            color: $color-white;
            height: 4rem;
            width: 10rem;
            display: block;
            margin-left: auto;
            border-radius: 5px !important;
            cursor: pointer;
            padding: 0 !important;
            .anticon {
              .anticon-spin {
                font-size: 2rem;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
</style>
